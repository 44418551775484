import React, { Component, useEffect, useState } from "react";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaShoppingBasket } from "react-icons/fa";
import { WhatsappShareButton } from "react-share";
import Slide from "react-reveal/Slide";
import {
  Row,
  Col,
  Container,
  Media,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Label,
  FormGroup,
  Spinner,
} from "reactstrap";
import { MdCancel } from "react-icons/md";
import styled from "styled-components";
import { connect } from "react-redux";
import { BsBag, BsPerson } from "react-icons/bs";
import Lottie from "react-lottie";
import foodLoader from "../assets/lotties/food-loader.json";
import Animate from "react-smooth/lib/Animate";
import { Zoom } from "react-reveal";

const LoaderComponent = (props) => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    return () => {
      setShowLoader(false);
    };
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: foodLoader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <React.Fragment>
      {window.location.host.includes("mowi") ? (
        <LogoLoader
          style={{ backgroundColor: "#49007D" }}
        >
          <img src={require("../themes/mowi/loader.webp")} alt="logo-loader" />
        </LogoLoader>
      ) : (
        <>
          <LoaderContainer
            style={{
              backgroundColor:
                props.settings && props.settings.darkMode === true
                  ? "#18191a"
                  : "#f6f6f6",
            }}
          >
            <Lottie options={defaultOptions} height={400} width={400} />
          </LoaderContainer>
          <Logo src={require("../assets/logo-full.png")} />
        </>
      )}
    </React.Fragment>
  );
};

const LogoLoader = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999999999;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;

    @media (min-width: 1000px) {
        background-size: contain;
    }

    img {
        position: absolute;
        top: 40%;
        width: 200px;
        animation: pulse 1.5s infinite;
    }

    @keyframes pulse {
        0% {
          transform: scale(1);
          opacity: 0.1
        }
        50% {
          transform: scale(1.1);
          opacity: 1
        }
        100% {
          transform: scale(1);
          opacity: 0.1
        }
      }


`;

const Logo = styled.img`
  position: fixed;
  width: 100px;
  bottom: 40px;
  margin-right: auto;
  margin-left: auto;
  left: 0;
  right: 0;
  opacity: 0.7;
  margin-top: 15%;
  z-index: 9999999999;

  @media (min-width: 1000px) {
    margin-left: auto !important;
  }
`;

const LoaderContainer = styled.div`
  height: 300px;
  background-color: red;
  height: 100%;
  position: fixed;
  margin-right: auto;
  margin-left: auto;
  left: 0;
  right: 0;
  top: 0%;
  z-index: 999999;

  svg {
    padding: 80px;
    g {
      g {
        g {
          g:nth-child(1),
          g:nth-child(2),
          g:nth-child(4),
          g:nth-child(5) {
            path {
              fill: orange;
            }
          }
        }
      }
    }
  }

  h1 {
    text-align: center;
    font-size: 1.1em;
    position: absolute;
    width: 100%;
    top: 104%;
    margin-left: 5px;
    font-weight: 600;
  }
`;

const mapStateToProps = (state) => ({
  order: state.order,
  settings: state.settings,
});
export default connect(mapStateToProps)(LoaderComponent);
