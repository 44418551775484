import { createStore } from "redux";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  partner: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "PARTNER":
      return {
        ...state,
        partner: action.data,
      };
    default:
      return state;
  }
};

const store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
