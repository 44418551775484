import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import SpanishTranslation from "./es.json";
import PortuguesTranslation from "./pt.json";
import EnglishTranslation from "./en.json";

import store from "../utils/store";

const storeData = store.getState();
const fallbackLng = [
  (storeData.settings && storeData.settings.language && storeData.settings.language !== "" ? storeData.settings.language : "es"),
];
const availableLanguages = ["es", "en", "pt"];

const resources = {
  es: {
    translation: SpanishTranslation,
  },
  en: {
    translation: EnglishTranslation,
  },
  pt: {
    translation: PortuguesTranslation,
  },
};



i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3', //Add this line
  resources,
  fallbackLng, // fallback language is english.
  detection: {
    checkWhitelist: true, // options for language detection
  },
  debug: false,
  whitelist: availableLanguages,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
