import React, { Suspense } from "react";
import { render } from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import AppRoutes from "./utils/routes";
import firebaseConfig from "./utils/firebase";
import { FirebaseAppProvider } from "reactfire";
import LoaderComponent from "./components/loaderComponent";
import { Provider } from "react-redux";
import store from "./utils/store";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "intl-pluralrules";
import "./utils/styles.scss";
import "./i18n/i18n";

const client = new ApolloClient({
  uri: process.env.REACT_APP_CORE_URL + "/graphql",
  cache: new InMemoryCache(),
  fetchOptions: {
    mode: "no-cors",
  },
});

render(
  <div className="app-style">
    <Provider store={store}>
      <ApolloProvider client={client}>
        <FirebaseAppProvider firebaseConfig={firebaseConfig}>
          <Suspense fallback={<LoaderComponent />}>
            <AppRoutes />
          </Suspense>
        </FirebaseAppProvider>
      </ApolloProvider>
    </Provider>
  </div>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.registerServiceWorker();
