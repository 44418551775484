import React, { Component } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import "./App.css";
import "react-notifications-component/dist/theme.css";

class App extends Component {
  static propTypes = {
    children: PropTypes.object.isRequired,
  };

  render() {
    const { children } = this.props;
    return (
      <div className="App notranslate">
        <Helmet>
          <title>Logpot.io</title>
          <meta
            name="description"
            content="Tu catálogo a un solo click de distancia"
          />
        </Helmet>
        {children}
      </div>
    );
  }
}

export default App;
