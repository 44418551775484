import React, { lazy } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { connect } from "react-redux";

import App from "../App";

const SurveyView = lazy(() => import("../views/surveyView"));
const BookingView = lazy(() => import("../views/bookingView"));
const HomeView = lazy(() => import("../views/homeView"));
const SuccessView = lazy(() => import("../views/successView"));

const AppRoutes = () => (
  <App>
    <HelmetProvider loading={<p>Cargando...</p>}>
      <BrowserRouter>
        <Route
          render={() => (
            <Switch>
              <Route exact path="/" component={HomeView} />
              <Route exact path="/survey" component={SurveyView} />
              <Route exact path="/booking" component={BookingView} />
              <Route exact path="/booking/success" component={SuccessView} />
            </Switch>
          )}
        />
      </BrowserRouter>
    </HelmetProvider>
  </App>
);

const mapStateToProps = (state) => ({
  status: state.status,
  user: state.user,
});

export default connect(mapStateToProps)(AppRoutes);
